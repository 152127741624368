import { template as template_7b16f6ff3e174113aff30f4dfaf37a9e } from "@ember/template-compiler";
const FKText = template_7b16f6ff3e174113aff30f4dfaf37a9e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
