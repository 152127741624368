import { template as template_2e365ba0e07b45a4a763dc7f604a1c80 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2e365ba0e07b45a4a763dc7f604a1c80(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
