import { template as template_07c73ab56d544fbf89367b0d7ed70907 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_07c73ab56d544fbf89367b0d7ed70907(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
