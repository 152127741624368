import { template as template_950b1ed4344c451c9a4826ee45c548b3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_950b1ed4344c451c9a4826ee45c548b3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
